import React from "react"
import { Link } from "react-router-dom"
import styles from "./footer.less"
import { t } from "../../../../config"
import { getuserAgent } from "../../tool/util"
import StylesSprite from "../../../assets/less/sprite.less"

let userAgent = "unknown"

class Component extends React.Component {
    componentDidMount() {
        userAgent = getuserAgent()
    }
    setDownloadApp= () => {
        if (userAgent === "Android") {
            return (
                <div className="col-md-4">
                    <a href="https://play.google.com/store/apps/details?id=com.tours4fun.t4f">
                        <div className={styles.footerLink}>
                            <div className={styles.footerIcon}>
                                <span className={`${StylesSprite.sprite_base} ${styles.icon2}`} />
                            </div>
                            <h4>{t("DOWNLOAD APP")}</h4>
                        </div>
                    </a>
                </div>
            )
        }
        if (userAgent === "iOS") {
            return (
                <div className="col-md-4">
                    <a href="https://itunes.apple.com/in/app/tours4fun-tours-travel-packages/id515820979?mt=8">
                        <div className={styles.footerLink}>
                            <div className={styles.footerIcon}>
                                <span className={`${StylesSprite.sprite_base} ${styles.icon2}`} />
                            </div>
                            <h4>{t("DOWNLOAD APP")}</h4>
                        </div>
                    </a>
                </div>
            )
        }
        return (
            <div className="col-md-4" onClick={() => alert("Unknown device")}>
                <a>
                    <div className={styles.footerLink}>
                        <div className={styles.footerIcon}>
                            <span className={`${StylesSprite.sprite_base} ${styles.icon2}`} />
                        </div>
                        <h4>{t("DOWNLOAD APP")}</h4>
                    </div>
                </a>
            </div>)
    }
    setProfile= () => (
        <div className="col-md-4">
            <Link to="/account.html">
                <div className={styles.footerLink}>
                    <div className={styles.footerIcon}>
                        <span className={`${StylesSprite.sprite_base} ${styles.icon3}`} />
                    </div>
                    <h4>{t("MY ACCOUNT")}</h4>
                </div>
            </Link>
        </div>
    )
    render() {
        return (
            <footer className={styles.footerSection}>
                <div className="footerrow">
                    <div className="col-md-4">
                        <Link to="/help">
                            <div className={styles.footerLink}>
                                <div className={styles.footerIcon}>
                                    <span className={`${StylesSprite.sprite_base} ${styles.icon1}`} />
                                </div>
                                <h4>{t("CONTACT US")}</h4>
                            </div>
                        </Link>
                    </div>
                    {this.setDownloadApp()}
                    {this.setProfile()}
                </div>
            </footer>
        )
    }
}


export default Component
